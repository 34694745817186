<template>
  <div>
    <div
      :class="[
        'Invitation',
        { Invitationbg: $route.query.langEnv == '3' },
        { Invitationbgen: $route.query.langEnv != '3' }
      ]"
    >
      <div class="Invitation-img">
        <div class="top">
          <img class="myQrCode" :src="getProductImageUrl(codeUrl)" alt="" />
          <div class="tips">
            {{ $t("邀請注冊成功得HK$100消費券 獎勵無上限") }}
          </div>
        </div>
      </div>
      <div class="ActivityRules" @click="handleViewActivityRules">
        {{ $t("邀請規則") }}
      </div>
      <div class="Invitation-btn">
        <div class="share-btn" @click="getShareWhatsApp">WhatsApp</div>
        <div class="save-btn" @click="getSaveImage">
          <img src="@/assets/icons/save.png" alt="" />
          {{ $t("保存圖片") }}
        </div>
      </div>
    </div>
    <div class="flow">
      <img
        v-if="$route.query.langEnv == '3'"
        src="@/assets/image/user/procedure.png"
        alt=""
      />
      <img v-else src="@/assets/image/user/procedureen.png" alt="" />
      <div class="Invitation-text">
        {{ $t("已成功邀請") }}
        <span class="Invitation-num"> {{ length }} </span>
        {{ $t("人") }}
        <div
          class="view-btn"
          v-if="length > 0"
          @click="handleViewInvitationList"
        >
          {{ $t("查看") }} <img src="@/assets/icons/right.png" alt="" />
        </div>
      </div>
    </div>
    <van-popup
      v-model:show="show"
      round
      position="bottom"
      :style="{ height: '66vh' }"
    >
      <div class="ActivityRulesdialog">
        <div class="title">
          <div @click="handleCloseActivityRulesdialog">
            <img src="@/assets/icons/Arrow_left.png" alt="" />
          </div>
          <div>{{ $t("邀請規則") }}</div>
        </div>
        <div class="content">
          <div class="ActivityRules-item">
            {{ $t("1. 邀請朋友註冊並通過 RITH 員工審核。") }}
          </div>
          <!-- <div class="ActivityRules-item-tips">
            注：认证成功指注册时提交的证件信息RITH工作人员审核通过后
          </div> -->
          <div class="ActivityRules-item">
            {{
              $t("2. 朋友通過審核後，您將獲得港幣 100 元代用券。檢查您的帳戶。")
            }}
          </div>
          <div class="ActivityRules-item">
            {{
              $t("3.消費券若未在有效期內使用，則會過期。平台將不再重複發放。")
            }}
          </div>
          <div class="ActivityRules-item">
            {{
              $t(
                "4.用戶必須正當、誠實地參加活動。否則，RITH可取消資格並追回獎勵。"
              )
            }}
          </div>
          <div class="ActivityRules-item">
            {{ $t("5. 如有任何疑問，請聯絡我們 +852 5717 1107。") }}
          </div>
        </div>
        <div class="confirm" @click="handleCloseActivityRulesdialog">
          {{ $t("確認") }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  getMyQrCode,
  getMyQrCodePlacard,
  getMyInviteList
} from "@/api/user.js";
import Mixins from "@/utils/mixins.js";
import baseURL from "@/utils/config";
import i18n from "@/i18n";
export default {
  mixins: [Mixins],
  data() {
    return {
      progress: 0,
      show: false,
      codeUrl: "",
      length: 0
    };
  },
  async created() {
    const langEnv = this.$route.query.langEnv;
    await this.getList();
    const res = await getMyInviteList({}, langEnv);
    this.handleChangeLang(langEnv, i18n);
    this.length = res.data?.length;
  },
  mounted() {
    document.title = this.$t("邀請碼");
  },
  methods: {
    extractCodeFromUrl(extractedCode) {
      const match = extractedCode.split(".")[0].split("/");
      console.log(match);

      if (match) {
        return match[match.length - 1];
      } else {
        return "";
      }
    },
    async getList() {
      const res = await getMyQrCode();
      this.codeUrl = res.data;
    },
    handleCloseActivityRulesdialog() {
      this.show = false;
    },
    handleViewActivityRules() {
      this.show = true;
    },
    async getSaveImage(item) {
      const res = await getMyQrCodePlacard();
      const url = this.getProductImageUrl(res.data);
      console.log("保存图片地址" + url);

      var func1 = function() {
        mmm.getSaveImage(url);
      };
      var func2 = function() {
        window.webkit.messageHandlers.getSaveImage.postMessage(url);
      };
      var func3 = function() {};
      this.excFunc(func1, func2, func3);
    },
    getShareWhatsApp(item) {
      const url =
        process.env.NODE_ENV === "production"
          ? `邀請你注冊e-Rith得好禮：
https://m.e-rith.com/register/?invitationCode=${this.extractCodeFromUrl(
              this.codeUrl
            )}`
          : `邀請你注冊e-Rith得好禮：
http://192.168.80.231:8008/register/?invitationCode=${this.extractCodeFromUrl(
              this.codeUrl
            )}`;
      console.log("二维码地址" + url);

      var func1 = function() {
        mmm.getWhatsShare(url);
      };
      var func2 = function() {
        window.webkit.messageHandlers.getWhatsShare.postMessage(url);
      };
      var func3 = function() {};
      this.excFunc(func1, func2, func3);
    },
    handleViewInvitationList() {
      this.$router.push({
        path: "/invitationList",
        query: {
          langEnv: this.$route.query.langEnv
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.Invitation {
  height: 519px;
  background-color: #fff;
  padding: 0 16px;

  .Invitation-img {
    position: absolute;
    left: 50%;
    top: 330px;
    transform: translate(-50%, -50%);
    text-align: center;
    .tips {
      padding-top: 16px;
      width: 159px;
      font-weight: 400;
      font-size: 11px;
      color: #666666;
    }
    img {
      height: 110px;
      width: 110px;
      margin: auto;
    }
  }
  .Invitation-tip {
    color: #666666;
  }

  .Invitation-btn {
    position: absolute;
    width: 100%;
    left: 0;
    top: 460px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    z-index: 1;
    .share-btn,
    .save-btn {
      text-align: center;
      flex: 1;
      padding: 12px 0;
      color: #fff;
      background: linear-gradient(180deg, #21f0cb 0%, #8bdd03 100%);
      box-shadow: 0px 1px 2px 0px rgba(79, 139, 20, 0.2);
      border-radius: 24px 24px 24px 24px;
    }
    .save-btn {
      color: #71b800;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 1px 2px 0px rgba(79, 139, 20, 0.2);
      margin-left: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }

  .ActivityRules {
    font-weight: 400;
    font-size: 11px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 13px 13px 13px 13px;
    display: inline-block;
    padding: 4px 10px;
    margin-top: 10px;
    position: absolute;
    right: 12px;
  }
}
.flow {
  position: relative;
  min-height: 37vh;
  padding: 16px;

  width: 100%;
  background: url("~@/assets/image/user/Invitationbottombg.png") no-repeat;
  img {
    height: 240px;
  }
  .Invitation-text {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
  }
}
.ActivityRulesdialog {
  padding: 20px 16px;
  .title {
    margin-bottom: 20px;
    font-size: 17px;
    display: flex;
    align-items: center;
    background-color: #fff;
    img {
      width: 24px;
    }
  }

  .confirm {
    width: 100%;
    height: 44px;
    background: #90d12e;
    border-radius: 8px 8px 8px 8px;
    font-size: 15px;
    color: #ffffff;
    text-align: center;
    padding: 10px 0;
  }
  .ActivityRules-item {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .ActivityRules-item-tips {
    color: #71b800;
    margin-bottom: 16px;
  }
}
.Invitationbg {
  background: url("~@/assets/image/user/InvitationCodebg.png") no-repeat;
  background-size: 100% 100%;
  margin: auto;
}
.Invitationbgen {
  background: url("~@/assets/image/user/InvitationCodebgen.png") no-repeat;
  background-size: 100% 100%;
  margin: auto;
}
.Invitation-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  .view-btn {
    margin-left: 6px;
    display: flex;
    align-items: center;
    img {
      width: 14px;
      height: 14px;
    }
  }
  .Invitation-num {
    margin: 0 6px;
    color: #90d12e;
  }
}
// .myQrCode{
//   height: 85px !important;
// }
</style>
